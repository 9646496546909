<template>
  <div></div>
</template>

<script>
export default {
  name: 'Home',
  mounted () {}
}
</script>

<style scoped>

</style>
